.delete-account-container {
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .delete-account-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .delete-account-container button {
    width: 100%;
    font-size: 16px;
  }
  